<template>
  <div class="wheys">
      404 找不到
  </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  }
};
</script>

<style >
.wheys{
  margin-top: 1rem;
}
</style>